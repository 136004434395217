<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-select remote value-key="uid" v-model="client_.firm_id" size="medium">
        <template #prefix>Empresa:</template>
        <el-option
          v-for="item in Firms"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        remote
        value-key="uid"
        v-model="client_.seller_id"
        size="medium"
      >
        <template #prefix>Vendedor:</template>
        <el-option
          v-for="item in Sellers"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        remote
        value-key="uid"
        v-model="client_.route_id"
        size="medium"
      >
        <template #prefix>Rota:</template>
        <el-option
          v-for="item in Routes"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-select
        remote
        value-key="uid"
        v-model="client_.margin_id"
        size="medium"
      >
        <template #prefix>Tabela:</template>
        <el-option
          v-for="item in Margins"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>

      <el-row type="flex" justify="space-between">
        <h5>Tipo</h5>
        <el-switch
          v-model="client_.type"
          active-text="Pessoa Jurídica"
          active-value="legal"
          inactive-value="personal"
          inactive-text="Pessoa Física"
          active-color="#409EFF"
          inactive-color="#409EFF"
        ></el-switch>
      </el-row>

      <el-collapse>
        <el-collapse-item title="Identificação" v-if="client_.type === 'legal'">
          <!-- PESSOA JURÍDICA -->
          <el-row>
            <el-col :md="12" :xs="24"
              ><base-input
                label="CNPJ:"
                v-model="client_legal.cnpj"
                mask="##!.###!.###!/####!-##"
                v-on:blur="checkIfPartnerExists()"
              >
              </base-input
            ></el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Insc. Estadual:"
                v-model="client_legal.state_registry"
              >
              </base-input
            ></el-col>
          </el-row>
          <base-input label="Nome Fantasia:" v-model="client_legal.social_name">
          </base-input>
          <base-input label="Razão Social:" v-model="client_legal.company_name">
          </base-input>
          <base-input
            label="Nome Responsável:"
            hint="Informe o nome do cliente"
            v-model="client_legal.owner_name"
          >
          </base-input>
          <base-input
            label="Telefone Responsável:"
            hint="Informe o telefone com DDD"
            mask="!(##!)#! ####!-####"
            v-model="client_legal.owner_contact"
          >
          </base-input>
        </el-collapse-item>
        <el-collapse-item title="Identificação" v-else>
          <!-- Pessoa Fisica -->

          <base-input
            label="CPF:"
            mask="###!.###!.###!-##"
            hint="Informe apenas os números"
            v-model="client_personal.cpf"
            v-on:blur="checkIfPartnerExists"
          >
          </base-input>
          <el-row :gutter="4">
            <el-col :md="12" :xs="24"
              ><base-input
                label="Nome:"
                hint="Informe o nome do cliente"
                v-model="client_personal.name"
              >
              </base-input
            ></el-col>
            <el-col :md="12" :xs="24"
              ><el-date-picker
                v-model="client_personal.birthday"
                type="date"
                size="medium"
                placeholder="Data de Nascimento:"
                format="DD/MM/YYYY"
              >
              </el-date-picker
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Contato">
          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input
                label="Telefone:"
                hint="Telefone com DDD"
                mask="!(##!)#! ####!-####"
                v-model="client_contact.phone"
              >
              </base-input
            ></el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Email:"
                hint="Informe o email completo"
                v-model="client_.email"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Endereço">
          <base-input
            label="CEP:"
            hint="00.0000-000"
            mask="##!.###!-###"
            v-on:blur="getAddressFromCEP"
            v-model="client_addr.postal_code"
          >
          </base-input>
          <el-row :gutter="4">
            <el-col :md="16" :xs="24">
              <base-input label="Rua:" v-model="client_addr.street">
              </base-input>
            </el-col>
            <el-col :md="8" :xs="24"
              ><base-input
                mask="#*"
                label="Número:"
                v-model="client_addr.number"
              >
              </base-input
            ></el-col>
          </el-row>

          <el-row :gutter="4">
            <el-col :md="8" :xs="24">
              <el-select v-model="client_addr.state" size="medium">
                <!--<template #prefix>Estado:</template>-->
                <el-option
                  v-for="item in States"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option> </el-select
            ></el-col>
            <el-col :md="16" :xs="24"
              ><base-input label="Cidade:" v-model="client_addr.city">
              </base-input
            ></el-col>
          </el-row>

          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input label="Bairro:" v-model="client_addr.place">
              </base-input>
            </el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Complemento:"
                v-model="client_addr.complement"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>

        <el-collapse-item title="Crédito">
          <el-row :gutter="4">
            <el-col :md="12" :xs="24">
              <base-input
                label="Crédito:"
                hint="Deixe 0 para ilimitado"
                type="money"
                v-model="client_credit.total_amount"
              >
              </base-input
            ></el-col>
            <el-col :md="12" :xs="24"
              ><base-input
                label="Prazo:"
                mask="##"
                hint="Dias de prazo para pagamento"
                v-model="client_credit.credit_days"
              >
              </base-input
            ></el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <el-row type="flex" justify="space-between">
        <h5>Produtor</h5>
        <el-switch
          v-model="client_.is_provider"
          active-text="Sim"
          :active-value="true"
          :inactive-value="false"
          inactive-text="Não"
          active-color="#409EFF"
          inactive-color="#409EFF"
        ></el-switch>
      </el-row>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button type="text" class="button" @click="save">Salvar</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
//import { jsonp } from "vue-jsonp";

export default {
  props: ["client", "showModal", "clientType"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      client_: {}, //{ ...(this.client || { type: this.clientType }) },
      client_addr: {}, //{ ...(this.client?.addr || {}) },
      client_contact: {}, //{ ...(this.client?.contact || {}) },
      client_legal: {}, //{ ...(this?.client?.legal || {}) },
      client_personal: {}, //{ ...(this?.client?.personal || {}) },
      client_credit: {}, //{ ...(this?.client?.credit || {}) },
      firms: null,
      sellers: null,
      routes: null,
      margins: null,
      isLoadingSave: false,
    };
  },
  watch: {
    client(v) {
      this.client_ = {
        ...(v || { type: this.clientType }),
        firm_id: v?.firm?.uid,
        seller_id: v?.seller?.uid,
        route_id: v?.route?.uid,
        margin_id: v?.margin?.uid,
      };
      this.client_addr = { ...(v?.addr || {}) };
      this.client_contact = { ...(v?.contact || {}) };
      this.client_legal = { ...(v?.legal || {}) };
      this.client_personal = { ...(v?.personal || {}) };
      this.client_credit = { ...(v?.credit || {}) };
    },
    clientType(v) {
      this.client_.type = v;
    },
  },
  mounted() {
    this.fetchMargins(true);
    this.fetchFirms(true);
    this.fetchSellers(true);
    this.fetchRoutes(true);
    this.client_.type = "legal";
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar cliente" : "Cadastrar cliente";
    },
    Sellers() {
      return this.sellers || [];
    },
    Routes() {
      return this.routes || [];
    },
    Margins() {
      return this.margins || [];
    },
    States() {
      return this.$store.state.constants.states;
    },
    Firms() {
      return this.firms || [];
    },
    isNew() {
      return !this.client;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    clearFields() {
      this.client_ = {};
      this.client_addr = {};
      this.client_contact = {};
      this.client_legal = {};
      this.client_personal = {};
      this.client_credit = {};
    },
    fetchFirms(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}firms`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.firms = json));
    },
    fetchSellers(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}sellers`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.sellers = json));
    },
    fetchRoutes(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}routes`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.routes = json));
    },
    fetchMargins(v) {
      if (v)
        fetch(`${this.$store.state.apiUrl}margins`, {
          credentials: "include",
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else return response.text();
          })
          .then((json) => (this.margins = json));
    },
    fetchPartnerDataFromCNPJ(cnpj) {
      const _cnpj = Number.parseInt(cnpj);
      if (Number.isNaN(_cnpj) || !this.isNew) return;

      const url = new URL(`${this.$store.state.apiUrl}apis/${_cnpj}`);

      url.search = new URLSearchParams({ api: "CNPJA" });
      fetch(url, {
        mode: "cors",
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else throw response.json();
        })
        .then(
          (json) => this.savePartnerInfo(json),
          () =>
            ElNotification.error({
              title: "Não foi possível obter informações sobre este CNPJ",
              message: "Preencha os campos manualmente",
            })
        );
    },
    checkIfPartnerExists() {
      if (this.client_.type === "personal") {
        fetch(
          `${this.$store.state.apiUrl}persons/${this.client_personal.cpf}`,
          {
            mode: "cors",
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        )
          .then((r) => {
            if (r.status == 200) return r.json();
            else throw r.json();
          })
          .then((json) => {
            if (!json?.partner?.is_client) {
              this.$alert(
                "Este parceiro já foi cadastrado como fornecedor, deseja cadastrá-lo como cliente?",
                {
                  confirmButtonText: "Cadastrar como cliente",
                  cancelButtonText: "Cancelar",
                }
              ).then(() => {
                fetch(
                  `${this.$store.state.apiUrl}partners/${json?.partner?.uid}`,
                  {
                    credentials: "include",
                    mode: "cors",
                    method: "PUT",
                    body: JSON.stringify({
                      is_client: true,
                    }),
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then(async (response) => {
                    if (response.status == 200) return await response.json();
                    else throw await response.json();
                  })
                  .then(
                    () => {
                      this.$emit("should-update");
                      ElNotification.success({
                        title: "Cliente cadastrado com sucesso",
                        position: "bottom-right",
                      });
                      this.$emit("close-modal");
                    },
                    (e) => {
                      ElNotification.error({
                        title: "Erro ao cadastrar",
                        message: e.message,
                        position: "bottom-right",
                      });
                    }
                  )
                  .finally(() => (this.isLoadingSave = false));
              });
            } else
              this.$alert(
                "Este CPF já foi cadastrado como cliente, você não pode cadastrar novamente",
                {
                  confirmButtonText: "Ok",
                }
              ).then(() => this.$emit("close-modal"));
          });
      } else {
        fetch(`${this.$store.state.apiUrl}legals/${this.client_legal.cnpj}`, {
          mode: "cors",
          credentials: "include",
          headers: {
            Accept: "application/json",
          },
        })
          .then((r) => {
            if (r.status == 200) return r.json();
            else throw r;
          })
          .then(
            (json) => {
              if (!json?.partner?.is_client) {
                this.$alert(
                  "Este parceiro já foi cadastrado como fornecedor, deseja cadastrá-lo como cliente?",
                  {
                    confirmButtonText: "Cadastrar como cliente",
                    cancelButtonText: "Cancelar",
                  }
                ).then(() => {
                  fetch(
                    `${this.$store.state.apiUrl}partners/${json?.partner?.uid}`,
                    {
                      credentials: "include",
                      mode: "cors",
                      method: "PUT",
                      body: JSON.stringify({
                        is_client: true,
                      }),
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  )
                    .then(async (response) => {
                      if (response.status == 200) return await response.json();
                      else throw await response.json();
                    })
                    .then(
                      () => {
                        this.$emit("should-update");
                        ElNotification.success({
                          title: "Cliente cadastrado com sucesso",
                          position: "bottom-right",
                        });
                        this.$emit("close-modal");
                      },
                      (e) => {
                        ElNotification.error({
                          title: "Erro ao cadastrar",
                          message: e.message,
                          position: "bottom-right",
                        });
                      }
                    )
                    .finally(() => (this.isLoadingSave = false));
                });
              } else
                this.$alert(
                  "Este CPF já foi cadastrado como cliente, você não pode cadastrar novamente",
                  {
                    confirmButtonText: "Ok",
                  }
                ).then(() => this.$emit("close-modal"));
            },
            () => {
              this.fetchPartnerDataFromCNPJ(this.client_legal.cnpj);
            }
          );
      }
    },
    savePartnerInfo(json) {
      if (json) {
        this.client_addr.street = json?.address?.street;
        this.client_addr.place = json?.address?.neighborhood;
        this.client_addr.postal_code = onlyNumbers(json?.address?.zip);
        this.client_addr.number = onlyNumbers(json?.address?.number);
        this.client_addr.complement = json?.address?.details;
        this.client_addr.state = json?.address?.state;
        this.client_addr.city = json?.address?.city;

        this.client_.email = json?.email;
        this.client_contact.phone = onlyNumbers(json?.phone);
        if (json?.membership?.length) {
          const [firstMember] = json.membership;

          this.client_legal.owner_name = firstMember.name;
          this.client_legal.owner_contact = json?.phone_alt;
        }

        if (json?.sintegra?.registrations?.length) {
          const [validRegistry] = json.sintegra.registrations
            .filter((r) => r.enabled)
            .slice(0, 1);

          if (validRegistry)
            this.client_legal.state_registry = validRegistry.number;
        }

        this.client_legal.company_name = json?.name;
        this.client_legal.social_name = json?.alias;
      }
    },
    switchPermission(action, event) {
      if (!Array.isArray(this.client_.permissions))
        this.client_.permissions = [];
      if (event) this.client_.permissions.push(action);
      else
        this.client_.permissions = this.client_.permissions.filter(
          (p) => p.slug != action.slug
        );
    },
    hasPermission(p) {
      return (
        this?.client_?.permissions?.some((_) => p.slug === _.slug) || false
      );
    },
    selectGroup(v) {
      this.client_.group = v;
    },
    getAddressFromCEP() {
      if (this?.client_?.type === "personal" && this.client_addr.postal_code) {
        fetch(`https://viacep.com.br/ws/${this.client_addr.postal_code}/json`)
          .then((r) => {
            if (r.status === 200) return r.json();
            else throw r.json();
          })
          .then((json) => {
            this.client_addr.street = json["logradouro"];
            this.client_addr.place = json["bairro"];
            this.client_addr.state = json["uf"];
            this.client_addr.city = json["localidade"];
            this.client_addr.complement = json["complemento"];
          })
          .catch(() =>
            ElNotification.error({
              title: "Não foi possível obter o endereço a partir do CEP",
              message: "Por favor, preencha o endereço manualmente",
            })
          );
      }
    },
    save() {
      this.isLoadingSave = true;
      !this.isNew ? this.updateClient() : this.createClient();
    },
    createClient() {
      fetch(`${this.$store.state.apiUrl}partners`, {
        credentials: "include",
        mode: "cors",
        method: "POST",
        body: JSON.stringify({
          ...this.client_,
          credit: this.client_credit,
          addr: this.client_addr,
          legal: this.client_.type === "legal" ? this.client_legal : null,
          personal:
            this.client_.type === "personal" ? this.client_personal : null,
          contact: this.client_contact,
          is_client: true,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: "Cliente cadastrado com sucesso",
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Erro ao cadastrar",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
    updateClient() {
      fetch(`${this.$store.state.apiUrl}partners/${this.client.uid}`, {
        credentials: "include",
        mode: "cors",
        method: "PUT",
        body: JSON.stringify({
          ...this.client_,
          credit: this.client_credit,
          addr: this.client_addr,
          legal: this.client_.type === "legal" ? this.client_legal : null,
          personal:
            this.client_.type === "personal" ? this.client_personal : null,
          contact: this.client_contact,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          () => {
            this.$emit("should-update");
            ElNotification.success({
              title: "Cliente atualizado com sucesso",
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Erro ao atualizar",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => (this.isLoadingSave = false));
    },
  },
  name: "ClientModal",
};
const onlyNumbers = (s) => s?.replace(/^[0-9]/g, "");
</script>
<style scoped>
.el-autocomplete {
  margin-top: 5px !important;
  display: block !important;
}
</style>