<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start"
            ><h4>Parceiros Cadastrados</h4></el-row
          ></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" justify="end">
            <el-col :md="12" :sm="16">
              <el-row type="flex" justify="end">
                <el-tabs
                  v-model="clientType"
                  @tab-click="changeClientType"
                  v-if="!SearchQuery"
                >
                  <el-tab-pane
                    label="Pessoa Fisica"
                    name="personal"
                  ></el-tab-pane>
                  <el-tab-pane
                    label="Pessoa Jurídica"
                    name="legal"
                  ></el-tab-pane>
                </el-tabs>
              </el-row>
            </el-col>

            <el-col :sm="8" :md="6" :lg="4">
              <el-row type="flex" justify="end">
                <el-button
                  type="primary"
                  icon="el-icon-s-operation"
                  size="medium"
                  @click="openFilterPartnersModal()"
                ></el-button>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  size="medium"
                  @click="openClientsModal(null)"
                ></el-button>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :data="Clients"
      :key="keys[0]"
      :cell-style="() => 'text-align:center;'"
      @row-click="openPartnerDetails"
      style="width: 100%; z-index: 0; cursor: pointer"
    >
      <el-table-column
        prop="legal.cnpj"
        label="cnpj"
        v-if="IsSearching || clientType === 'legal'"
      >
      </el-table-column>
      <el-table-column
        prop="personal.cpf_formatted"
        label="cpf"
        v-if="IsSearching || clientType === 'personal'"
      >
      </el-table-column>
      <el-table-column
        prop="legal.social_name"
        label="nome fantasia"
        v-if="IsSearching || clientType === 'legal'"
      >
      </el-table-column>
      <el-table-column
        prop="personal.name"
        label="nome"
        v-if="IsSearching || clientType === 'personal'"
      >
      </el-table-column>
      <el-table-column
        prop="legal.company_name"
        label="razão social"
        v-if="IsSearching || clientType === 'legal'"
      >
      </el-table-column>

      <el-table-column
        prop="legal.owner_name"
        label="responsável"
        v-if="IsSearching || clientType === 'legal'"
      >
      </el-table-column>
      <el-table-column prop="contact.phone" label="telefone"> </el-table-column>
      <el-table-column
        prop="email"
        label="email"
        v-if="IsSearching || clientType === 'personal'"
      >
      </el-table-column>
      <el-table-column label="tipo">
        <template #default="c">
          <el-checkbox-button
            label="C"
            true-label="client"
            :checked="c.row.is_client"
            @click.prevent.stop="
              () => (c.row.is_client = !c.row.is_client) | updateClient(c.row)
            "
            >C</el-checkbox-button
          >
          <el-checkbox-button
            label="F"
            false-label="provider"
            :checked="c.row.is_provider"
            @click.prevent.stop="
              () =>
                (c.row.is_provider = !c.row.is_provider) | updateClient(c.row)
            "
            >P</el-checkbox-button
          >
        </template>
      </el-table-column>
      <el-table-column label="ações" :width="100">
        <template #default="client">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="
                  () =>
                    (client.row.is_active = !client.row.is_active) |
                    updateClient(client.row)
                "
                :title="
                  client.row.is_active
                    ? 'Bloquear cliente?'
                    : 'Desbloquear cliente?'
                "
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    :type="client.row.is_active ? 'primary' : 'danger'"
                    circle
                    ><i
                      :class="{
                        'el-icon-lock': !client.row.is_active,
                        'el-icon-unlock': client.row.is_active,
                      }"
                    ></i
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click.prevent.stop="openClientsModal(client.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteClient(client.row)"
                title="
              Remover cliente?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="pagination = $event"
      :current-page="pagination"
      background
      layout="prev, pager, next"
      :page-count="clients?.lastPage"
    >
    </el-pagination>
    <client-modal
      :showModal="showClientModal"
      :client="client"
      :clientType="clientType"
      @close-modal="showClientModal = false"
      @should-update="fetchClients"
    ></client-modal>
    <filter-partners-modal
      :showModal="showFilterPartnersModal"
      :client="client"
      :clientType="clientType"
      @close-modal="showFilterPartnersModal = false"
      @update-filters="fetchClients"
    ></filter-partners-modal>
  </el-card>
</template>

<script>
import ClientModal from "./modals/Client.vue";
import { uuid } from "vue-uuid";
import { maska } from "maska";
import FilterPartnersModal from "./modals/FilterPartnersModal.vue";

import PartnerService from "../services/partners";
import { notifySuccess, notifyError } from "../utils/notifiers";

export default {
  name: "UserGroups",
  components: { ClientModal, FilterPartnersModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    keys: [uuid.v4(), uuid.v4()],
    clientType: "legal",
    createMask: maska,
    clients: null,
    client: null,
    pagination: 1,
    showClientModal: false,
    showFilterPartnersModal: false,
  }),
  mounted() {
    this.fetchClients();
  },
  watch: {
    clients() {
      this.rebuildTables();
    },
    SearchQuery() {
      this.fetchClients();
    },
    pagination() {
      this.fetchClients();
    },
  },
  methods: {
    openPartnerDetails(p) {
      this.$router.push("/parceiros/" + p.uid);
    },
    openClientsModal(e) {
      (this.client = e), (this.showClientModal = true);
    },
    openFilterPartnersModal() {
      this.showFilterPartnersModal = true;
    },
    changeClientType(tab) {
      this.clientType = tab.props.name;
      this.pagination = 1;
      this.fetchClients();
    },
    rebuildTables() {
      this.keys = [uuid.v4(), uuid.v4()];
    },
    getCheckboxValues(client) {
      return [
        client.is_client ? "client" : "",
        client.is_provider ? "provider" : "",
      ];
    },
    async deleteClient(client) {
      if (client) {
        const { partner, error } = await PartnerService(client.uid).delete();

        if (partner) notifySuccess("Parceiro removido com sucesso");
        else notifyError("Não foi possível remover o parceiro", error.message);
      }
    },
    async updateClient(client) {
      if (client) {
        const { partner, error } = await PartnerService(client.uid).update(
          client
        );

        if (partner) notifySuccess("Parceiro atualizado com sucesso");
        else
          notifyError("Não foi possível atualizar o parceiro", error.message);
      }
    },
    async fetchClients(filter) {
      const filterOptions = filter || {};

      if (hasOnlyDigits(this.SearchQuery))
        filterOptions["searchCpfCnpj"] = this.SearchQuery;
      else filterOptions["searchName"] = this.SearchQuery;

      if (this.SearchQuery.length === 0)
        filterOptions["partnerType"] = this.clientType;

      this.isLoading = true;

      const { partners } = await PartnerService().index({
        ...filterOptions,
        pagination: this.pagination,
      });

      if (partners) this.clients = partners;

      this.isLoading = false;
    },
  },
  computed: {
    OnlyLegalClients() {
      return this.Clients.filter((c) => c.type === "legal");
    },
    SearchQuery() {
      return this.$store.state.searchNameQuery;
    },
    IsSearching() {
      return this.SearchQuery.length > 0;
    },
    OnlyPersonalClients() {
      return this.Clients.filter((c) => c.type === "personal");
    },
    Clients() {
      return this.clients?.data || [];
    },
  },
};
const hasOnlyDigits = (s) => /^\d+$/.test(s);
</script>
<style scoped>
.el-card {
  width: 100%;
}
</style>
